import React from 'react';

import styles from "./styles/Details.module.scss"
import { ROUTES } from './contants';
import EditProfile from './EditProfile';
import ChangePassword from './ChangePassword';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import { IoIosArrowBack } from 'react-icons/io';
import ContactUs from './ContactUs';
import PostSettings from './PostSettings';

export default function Details({ isMobile = false, route = '', setRoute }) {

    const handleBackButtonClck = () => {
        setRoute('');
    }

    return (
        <div className={styles.Details}>
            {!isMobile ? '' :
                <span
                    className={styles.backButton}
                    onClick={handleBackButtonClck}
                >
                    <IoIosArrowBack />
                </span>
            }
            {
                (route === ROUTES.EDIT_PROFILE) ? <EditProfile /> :
                    (route === ROUTES.CHANGE_PASSWORD) ? <ChangePassword /> :
                        (route === ROUTES.TERMS_CONDITIONS) ? <TermsAndConditions /> :
                            (route === ROUTES.PRIVACY_POLICY) ? <PrivacyPolicy /> :
                                (route === ROUTES.CONTACT_US) ? <ContactUs /> :
                                    (route === ROUTES.POST_SETTING) ? <PostSettings /> :
                                        ''}


        </div>
    )
}
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken, getCSRF_Token } from "./localStorageService";
import { API_URL, INITIAL_TOKEN } from "../config/config";


export const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers, { getState }) => {
            headers.set("token", INITIAL_TOKEN);
            headers.set("Accept", "application/json");
            return headers;
        },
    }),
    endpoints: () => ({}),
});
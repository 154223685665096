import React, { useState } from "react";

import Login from "../Account/Login";
import { ROUTES } from "./Route";
import SubscriptionManagementScreen from "./SubscriptionManagementScreen";

export default function PostManagement() {
  const [page, setPage] = useState(ROUTES.POST_MANAGEMENT);

  switch (page) {
    case ROUTES.POST_MANAGEMENT:
      return <SubscriptionManagementScreen setPage={setPage} />;
    default:
      return <Login />;
  }
}

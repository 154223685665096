import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requests: [],
};

export const cashOutSlice = createSlice({
  name: "cashOut",
  initialState,
  reducers: {
    updateCashOut: (state, action) => {
      // console.log('update Users' + action.payload);
      state.requests = action.payload;
    },
  },
});

export const { updateCashOut } = cashOutSlice.actions;

export default cashOutSlice.reducer;

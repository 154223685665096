import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { SubTitle } from "../../Components/CustomComponents";
import Table from "../../Components/Table/Table";
import styles from "./styles/user_management.module.scss";

//
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
import { ShimmerTable } from "react-shimmer-effects";
import { updatePosts } from "../../features/postSlice";
import { useGetAllPostsQuery } from "../../services/postApi";

const PostManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetAllPostsQuery({ refetchOnMountOrArgChange: true });

  var postData = useSelector((state) => state.post.posts);

  useEffect(() => {
    setRowData(postData);
  }, [postData]);

  useEffect(() => {
    if (data && isSuccess) {
      dispatch(updatePosts(data.data));
    }
  }, [isSuccess, data, dispatch]);

  function indication() {
    // return something here
    // return 'Table is empty';
    return <ShimmerTable row={5} col={3} />;
  }

  const columns = [
    {
      dataField: "pet_image",
      text: "Post Image",
      // sort: true,
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        return <img alt="post" src={row.pet_image} width={"150"} />;
      },
    },
    {
      dataField: "created_by",
      text: "User",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "like_count", //todo:
      text: "Vote",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {
        // deleteModal ? (
        //   <DeleteModal
        //     handleDeleteModalClose={handleDeleteModalClose}
        //     data={editedData}
        //     onDelete={deleteData}
        //   />
        // ) : (
        //   ""
        // )}
        // {editModal ? (
        //   <EditEventModal
        //     handleEditModalClose={handleEditModalClose}
        //     data={editedData}
        //   />
        // ) : (
        //   ""
        // )
      }

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Post Management"} />
        </div>
        {
          // <Row>
          //   <Col md={12}>
          //     <Button
          //       classes={`${styles.cardBtn} `}
          //       text="Add Event"
          //       onClick={handleAddUser}
          //     ></Button>
          //   </Col>
          // </Row>
        }
      </div>

      <div className={styles.mainTable}>
        {
          // <Table data={rowData} columns={columns} indication={indication} />
          <Table
            data={rowData ?? []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default PostManagementScreen;

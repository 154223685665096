import React, { useEffect, useState } from "react";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editModal.module.scss";
import { FaCircleXmark } from "react-icons/fa6";
import { Col, Form, Row } from "react-bootstrap";
import { Input, Button } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import _ from 'lodash';
import { Controller, useForm } from "react-hook-form";

const EditUserModal = ({ handleEditModalClose, data, handleUpdate }) => {
  const { handleSubmit, control } = useForm({});

  const [userSlug, setUserSlug] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [selectedStatus, setSelectedStatus] = useState('');

  const statusList = [
    { label: "Active", value: "active" },
    { label: "Block", value: "block" },
  ];

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (data) {
      setUserSlug(data.slug);
      setUserEmail(data.email);
      if (data.status === true) {
        setSelectedStatus("Active");
      } else {
        setSelectedStatus("Block");
      }
    }
  }, [data, mounted]);

  if (!mounted) {
    return;
  }


  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0 secondary-text"> {data ? "Edit User" : "Add User"} </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <Form onSubmit={handleSubmit(handleUpdate)}>
              <Row>
                <Col md={12}>
                  <p className="m-0 black-text">
                    User Email
                  </p>
                  <Input
                    classes="mt-2 mb-3"
                    type="email"
                    placeholder="Enter user email"
                    value={userEmail}
                    disable={true}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p className="m-0 black-text" >
                    Status
                  </p>
                  <Controller
                    control={control}
                    name="status"
                    rules={{}}
                    defaultValue={selectedStatus}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <SelectFieldComponent
                        firstOption={
                          _.isEmpty(selectedStatus)
                            ? "Select Status"
                            : selectedStatus
                        }
                        optionsList={statusList}
                        onSelectChange={onChange}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Button
                    classes={`${cssStyles.cardBtn} mt-4 `}
                    text={data ? "Update" : "Save"}
                    // onClick={handleSubmit}
                    type="submit"
                  ></Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditUserModal;

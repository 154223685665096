import React, { useState } from "react";

import Login from "../Account/Login";
import PostReportManagementScreen from "./PostReportManagementScreen";
import { ROUTES } from "./Route";

export default function PostReportManagement() {
  const [page, setPage] = useState(ROUTES.POST_REPORT_MANAGEMENT);

  switch (page) {
    case ROUTES.POST_REPORT_MANAGEMENT:
      return <PostReportManagementScreen setPage={setPage} />;
    default:
      return <Login />;
  }
}

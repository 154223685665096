import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { FaUserEdit } from "react-icons/fa";
import { Button, SubTitle } from "../../Components/CustomComponents";
import Table from "../../Components/Table/Table";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/user_management.module.scss";

//
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
import { ShimmerTable } from "react-shimmer-effects";
import BeatLoader from "react-spinners/BeatLoader";
import EditEventModal from "../../Components/Modals/EditEventModal";
import { updateEvents } from "../../features/eventSlice";
import {
  useCloseEventMutation,
  useCreateEventMutation,
  useGetAllEventsQuery,
  useUpdateEventMutation,
} from "../../services/eventApi";

//
// import dayjs from 'dayjs';
// import { utc } from 'dayjs/plugin/utc';

const EventManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetAllEventsQuery({ refetchOnMountOrArgChange: true });

  console.log(data);

  var eventData = useSelector((state) => state.event.events);
  // console.log(`eventData = ${eventData}`);

  useEffect(() => {
    setRowData(eventData);
  }, [eventData]);

  useEffect(() => {
    if (data && isSuccess) {
      // console.log("isSuccess = " + data.data);
      dispatch(updateEvents(data.data));
    }
  }, [isSuccess, data, dispatch]);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditedData("");
  };

  const handleAddEvent = () => {
    setEditModal(true);
  };

  const [closeEvent, { isLoading: isClosing }] = useCloseEventMutation();

  const handleCloseEvent = async () => {
    console.log("hangleCloseEvent");
    const { data, error } = await closeEvent();
    // console.log(data)
    // console.log(error)
    if (error && error.status === 400) {
      Toast(error.data.message, "error");
      return;
    }

    if (data && data.code === 200) {
      await refetch();
      SuccessToast(data.message);
    }
  };

  const [createEvent, { isLoading: isCreating }] = useCreateEventMutation();

  const handleAdd = async (data1) => {
    console.log(data1);
    console.log(data1.event_started_date);
    console.log(data1.event_end_date);

    const utc_event_started_date = new Date(
      Date.UTC(
        data1.event_started_date.$y,
        data1.event_started_date.$M,
        data1.event_started_date.$D,
        data1.event_started_date.$H,
        data1.event_started_date.$m,
        data1.event_started_date.$s
      )
    );

    const utc_event_end_date = new Date(
      Date.UTC(
        data1.event_end_date.$y,
        data1.event_end_date.$M,
        data1.event_end_date.$D,
        data1.event_end_date.$H,
        data1.event_end_date.$m,
        data1.event_end_date.$s
      )
    );

    const modifiedData = {
      event_started_date: utc_event_started_date,
      event_end_date: utc_event_end_date,
    };

    console.log(modifiedData);

    // debugger
    // return;

    const { data, error } = await createEvent(modifiedData);
    // console.log(data);
    console.log(error);

    if (error && error.status === 400) {
      Toast(error.data.message, "error");
      return;
    }

    if (data && data.code === 200) {
      await refetch();
      handleEditModalClose();
      SuccessToast(data.message);
    }
  };

  const [updateEvent, { isLoading: isUpdating }] = useUpdateEventMutation();

  const handleUpdate = async (modifiedData) => {
    // console.log(modifiedData);
    // console.log(editedData.slug);
    const eventSlug = editedData.slug;

    const { data, error } = await updateEvent({ modifiedData, eventSlug });
    console.log(data);
    console.log(error);

    if (error && error.status === 400) {
      Toast(error.data.message, "error");
      return;
    }

    if (data && data.code === 200) {
      await refetch();
      handleEditModalClose();
      SuccessToast(data.message);
    }
  };

  function indication() {
    // return something here
    // return 'Table is empty';
    return <ShimmerTable row={5} col={5} />;
  }

  const columns = [
    {
      dataField: "event_name",
      text: "Event Name",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "started_at",
      text: "Start at",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "end_at", //todo:
      text: "End at",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      // formatter: (cellContent, row) => {
      // return <span>🔥 {row.age}</span>;
      // },
    },
    // {
    //   dataField: "timer",
    //   text: "Counter",
    //   sort: true,
    //   headerAlign: "center",
    //   align: 'center',
    //   headerStyle: {
    //     width: '150px',
    //   },
    //   formatter: (cellContent, row) => {

    //     const time = new Date(row.end_at);

    //     return <Timer expiryTimestamp={time} />
    //   },
    // },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "120px",
      },
      formatter: (cellContent, row) => {
        if (row.status === true)
          return <div className={styles.active}> Active </div>;
        if (row.status === false)
          return <div className={styles.block}>Completed</div>;
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.slug) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              {
                <div
                  className={`${styles.btnBg} d-flex justify-content-center align-items-center text-white rounded-3 mx-2 p-2 `}
                  role="button"
                  onClick={() => {
                    setEditedData(row);
                    handleEditModalOpen(true);
                  }}
                >
                  <span
                    className={`align-items-center d-flex justify-content-center pe-1 `}
                  >
                    <FaUserEdit />
                  </span>
                  <span>Edit</span>
                </div>
              }
              {
                // <div
                //   className="d-flex justify-content-center align-items-center bg-danger text-white rounded-3 p-2 mx-2 "
                //   role="button"
                //   onClick={() => {
                //     setEditedData(row);
                //     handleDeleteModalOpen();
                //   }}
                // >
                //   <span className={`d-flex align-items-center pe-1`}>
                //     <MdDeleteForever />
                //   </span>
                //   <span>Delete</span>
                // </div>
              }
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {
        //   deleteModal ? (
        //   <DeleteModal
        //     handleDeleteModalClose={handleDeleteModalClose}
        //     data={editedData}
        //     onDelete={deleteData}
        //   />
        // ) : (
        //   ""
        // )
      }

      {editModal ? (
        <EditEventModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
          handleAdd={handleAdd}
          handleUpdate={handleUpdate}
          isCreating={isCreating}
          isUpdating={isUpdating}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Event Management"} />
        </div>
        {
          <div className="align-items-center d-flex justify-content-center">
            <div className="p-1 w-auto">
              <Button
                classes={`${styles.cardBtn} `}
                text="Add Event"
                onClick={handleAddEvent}
              ></Button>
            </div>
            <div className="p-1 w-auto">
              <Button
                text={isClosing ? <BeatLoader size={10} /> : "Close Event"}
                classes={`mt-3 mb-2 bg-danger ${
                  isClosing ? "bg-danger-subtle" : ""
                }`}
                onClick={handleCloseEvent}
                disabled={isClosing}
              ></Button>
            </div>
          </div>
        }
      </div>

      <div className={styles.mainTable}>
        {
          // <Table data={rowData} columns={columns} indication={indication} />
          <Table
            data={rowData ?? []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default EventManagementScreen;

import React from "react";
import { Navigate } from "react-router-dom";
import { baseRoute } from "../../Helper";
import { WEB_ROUTES } from "../../constants";
import { getToken } from "../../services/localStorageService";
// import { GetTokenLocalStorage } from "../../services_axios_old/Localstorage";


export default function PublicRoute({ children }) {

    // const token = GetTokenLocalStorage() //todo:
    // const token = false;
    const token = getToken();


    return (
        token ? <Navigate to={baseRoute(WEB_ROUTES.DASHBOARD)} replace /> : children

    );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  events: [],
};

export const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    updateEvents: (state, action) => {
      // console.log('update Users' + action.payload);
      state.events = action.payload;
    },
  },
});

export const { updateEvents } = eventSlice.actions;

export default eventSlice.reducer;

import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react';
// import { setupListeners } from '@reduxjs/toolkit/query';
import { cashOutApi } from "./services/cashOutApi";
import { dashboardApi } from "./services/dashboardApi";
import { eventApi } from "./services/eventApi";
import { notificationApi } from "./services/notificationApi";
import { postApi } from "./services/postApi";
import { settingApi } from "./services/settingApi";
import { SubscriptionApi } from "./services/subscriptionApi";
import { userApi } from "./services/userApi";
import { userAuthApi } from "./services/userAuthApi";

import authReducer from "./features/authSlice";
import cashOutReducer from "./features/cashOutSlice";
import dashboardReducer from "./features/dashboardSlice";
import eventReducer from "./features/eventSlice";
import postReducer from "./features/postSlice";
import settingReducer from "./features/settingSlice";
import subscriptionReducer from "./features/subscriptionSlice";
import userReducer from "./features/userSlice";
import sessionMiddleware from "./middleware/sessionMiddleware";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    // RTK Query API
    [userAuthApi.reducerPath]: userAuthApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [settingApi.reducerPath]: settingApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [cashOutApi.reducerPath]: cashOutApi.reducer,
    [eventApi.reducerPath]: eventApi.reducer,
    [SubscriptionApi.reducerPath]: SubscriptionApi.reducer,

    // Local storage Redux
    auth: authReducer,
    dashboard: dashboardReducer,
    user: userReducer,
    post: postReducer,
    cashOut: cashOutReducer,
    setting: settingReducer,
    event: eventReducer,
    subscription: subscriptionReducer,
  },
  // load from local storage
  // preloadedState: loadFromLocalStorage(), //todo:

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  //todo:
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      userAuthApi.middleware,
      dashboardApi.middleware,
      notificationApi.middleware,
      settingApi.middleware,
      postApi.middleware,
      cashOutApi.middleware,
      eventApi.middleware,
      userApi.middleware,
      SubscriptionApi.middleware,
      sessionMiddleware, // for session check each api response
    ]),
});

//todo:
// function saveToLocalStorage(state) {
//     try {
//         const serialState = JSON.stringify(state)
//         localStorage.setItem("reduxStore", serialState)
//     } catch (e) {
//         console.warn(e);
//     }
// }

//todo:
// function loadFromLocalStorage() {
//     try {
//         const serialisedState = localStorage.getItem("reduxStore");
//         if (serialisedState === null) return undefined;
//         return JSON.parse(serialisedState);
//     } catch (e) {
//         console.warn(e);
//         return undefined;
//     }
// }

//todo:
// store.subscribe(() => saveToLocalStorage(store.getState()));

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch) //todo:

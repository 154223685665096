import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { Button, Input, SubTitle } from "../../Components/CustomComponents";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { useUpdateSettingPagesMutation } from "../../services/settingApi";
import styles from "./styles/StaticPage.module.scss";

const PostSettings = () => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  var per_day_post = useSelector((state) => state.setting.per_day_post);
  var price = useSelector((state) => state.setting.price);

  useEffect(() => {
    if (price) {
      setValue("price", price);
    }
    if (per_day_post) {
      setValue("per_day_post", per_day_post);
    }
  }, [price, per_day_post, setValue]);

  const [updateInfo, { isLoading }] = useUpdateSettingPagesMutation();

  const handleDataSubmission = async (data) => {
    console.log(data);
    try {
      const response = await updateInfo(data);

      if (response.data.code === 200) {
        SuccessToast(response?.data?.message);
      }
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        Toast(error?.response?.data?.message, "error");
      } else {
        Toast("An error occurred during login", "error");
      }
    }
  };

  return (
    <div className={styles.StaticPage}>
      {errors
        ? errors.price?.message
          ? Toast(errors.price?.message, "error")
          : errors.per_day_post?.message
          ? Toast(errors.per_day_post?.message, "error")
          : null
        : null}

      <Form onSubmit={handleSubmit(handleDataSubmission)}>
        <Row>
          <Col sm={10}>
            <SubTitle text="Post Settings" classes="mb-4" />
          </Col>
          <Col sm={2}>
            <Button
              classes={`${styles.cardBtn} ${isLoading ? "bg-dark-subtle" : ""}`}
              text={isLoading ? <BeatLoader size={10} /> : "Save"}
              // onClick={handleSaveText}
              type="submit"
              disabled={isLoading}
            ></Button>
          </Col>
        </Row>
        <p className="">
          Set your email, App user will contact you on this email.
        </p>
        <Row>
          <Col md={12}>
            <p className="m-0">Price</p>
            <Controller
              control={control}
              name="price"
              rules={{
                required: "Price is required.",
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  classes="mt-2 mb-3"
                  type="text"
                  placeholder="Enter price"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <p className="m-0">
              Per day post (no of per day post for each user)
            </p>
            <Controller
              control={control}
              name="per_day_post"
              rules={{
                required: "Per day post is required.",
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  classes="mt-2 mb-3"
                  type="number"
                  placeholder="per day post number"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PostSettings;

import React from "react";
import { useForm, Controller } from "react-hook-form"
import { Form, InputGroup } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'

import { Button, CustomInput, Description, Input, Title } from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { ACCOUNT_ENUM } from "./Route";
import Toast, { SuccessToast } from "../../Hooks/useToast";
// import { ForgetPassword } from "../../services_axios_old/auth";
import { useForgotPasswordMutation } from "../../services/userAuthApi";
import { WEB_ROUTES } from "../../constants";

export default function ForgotPassword({ setPage }) {
    const { handleSubmit, control, formState: { errors } } = useForm()
    const navigate = useNavigate()

    const [forgetPassword, { isError, isLoading, isSuccess }] = useForgotPasswordMutation();

    const handleBackButtonClick = () => {
        setPage(ACCOUNT_ENUM.LOGIN)
    }

    const handleDataSubmit = async (userData) => {
        console.log(userData);

        try {

            const response = await forgetPassword(userData);

            console.log(response);

            if (response.data.code === 200) {

                SuccessToast(response?.data?.message)

                navigate(WEB_ROUTES.ACCOUNT)

            }

        } catch (error) {
            // console.log(error.data.message, 'errrrr');
            if (error.data && error.data.message) {
                Toast(error?.data?.message, "error");
            } else {
                Toast("An error occurred during login", "error");
            }
        }
    }

    return (
        <AccountLayout
            hasBackButton={true}
            handleBackButton={handleBackButtonClick}
        >
            {
                errors
                    ? errors.email?.message
                        ? Toast(errors.email?.message, 'error') : null
                    : null
            }
            <Title text="Forgot Password" classes="mb-2" />
            <Description
                text="In order to reset your password you need to enter your registered email address."
                classes="text-center fs-7"
            />
            <Form onSubmit={handleSubmit(handleDataSubmit)}>
                <Controller
                    control={control}
                    name="email"
                    rules={{
                        required: "Email is required.",
                        pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Enter a valid email address.",
                        },
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <InputGroup className="mb-3"
                            style={{
                                width: '300px'
                            }}
                        >
                            <Input
                                placeholder="Email"
                                type="email"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        </InputGroup>
                    )}
                />
                <Button
                    text='Continue'
                    classes="mt-3 mb-2"
                    // disabled={isLoading}
                    // onClick={handleSubmit}
                    type='submit'
                />
            </Form>
        </AccountLayout>
    )
}
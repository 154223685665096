import React, { useState } from 'react'

import { ROUTES } from "./Route";
import Login from '../Account/Login';
import CashOutRequestManagementScreen from './CashOutRequestManagementScreen';

export default function CashOutRequestManagement() {

    const [page, setPage] = useState(ROUTES.CASH_MANAGEMENT)

    switch (page) {
        case ROUTES.CASH_MANAGEMENT:
            return <CashOutRequestManagementScreen setPage={setPage} />
        default:
            return <Login />
    }


}

import React, { useMemo } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

// global scss for globally styles and fonts
import "./_fonts.scss";
import "./_global.scss";

import PublicRoute from "./Components/Routes/PublicRoute.js";
import { WEB_ROUTES } from "./constants.js";
import { baseRoute } from "./Helper";

import ProtectedLayout from "./Components/Layouts/ProtectedLayout.js";
import Account from "./Pages/Account";
import Dashboard from "./Pages/Dashboard";
// import Tournaments from "./Pages/Tournaments";
// import Messages from "./Pages/Messages";
import Setting from "./Pages/Setting";
// import Profile from "./Pages/Profile";
// import PaymentMethod from "./Pages/PaymentMethod";
import { uniqueId } from "lodash";
import ProtectedRoute from "./Components/Routes/ProtectedRoute.js";

import CashOutRequestManagement from "./Pages/CashOutRequestManagement/index.js";
import EventManagement from "./Pages/EventManagement/index.js";
import PostManagement from "./Pages/PostManagement/index.js";
import PostReportManagement from "./Pages/PostReportManagement/index.js";
import SendNotificationScreen from "./Pages/SendNotification/index.js";
import SubscriptionManagementScreen from "./Pages/SubscriptionManagement/index.js";
import UserManagementScreen from "./Pages/UserManagement/index.js";
// import TournamentManagementScreen from "./Pages/TournamentManagement/TournamentManagementScreen.js";
// import TournamentManagement from './Pages/TournamentManagement';
function App() {
  // todo: menu
  const ROUTES = useMemo(() => {
    return [
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.ACCOUNT)}
        element={
          <PublicRoute>
            <Account />
          </PublicRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.DASHBOARD)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={0}>
              <Dashboard />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.USER_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={1}>
              <UserManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.EVENT_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={2}>
              <EventManagement />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.POST_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={3}>
              <PostManagement />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.POST_REPORT_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={4}>
              <PostReportManagement />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.SUBSCRIPTION_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={5}>
              <SubscriptionManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.CASH_OUT_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={6}>
              <CashOutRequestManagement />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,

      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.SEND_NOTIFICATION)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={7}>
              <SendNotificationScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.SETTING)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={8}>
              <Setting />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      // <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.PROFILE)} element={
      //   <ProtectedRoute>
      //     <ProtectedLayout isSearchBar={false} selectedRoute={2}>
      //       <Profile />
      //     </ProtectedLayout>
      //   </ProtectedRoute>
      // } />,
    ];
  }, []);

  return (
    <Router>
      <Routes>
        {ROUTES.map((item) => item)}
        <Route
          path={baseRoute("*")}
          element={<Navigate to={baseRoute(WEB_ROUTES.ACCOUNT)} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
